import React, {Component} from 'react';
//import './App.css';
import Iframe from 'react-iframe'

class App extends Component {
    render() {

        var frames = [
            {
                url: 'https://masjiddashboard.herokuapp.com/?masjidid=4230',
                width: "1920px",
                height: "1080px",
                top: '-136px',
                left: '296px',
                id: "myId",
                zoom: '0.75'
            },
            {
                url: 'https://masjiddashboard.herokuapp.com/?masjidid=4230&dir=v',
                width: "1080px",
                height: "2160px",
                top: '-540px',
                left: '-270px',
                id: "myId",
                zoom: '0.5'
            }

        ]
        return (
            <div className="App">
                {/*<div className="App-header">*/}
                {/*  <img src={logo} className="App-logo" alt="logo" />*/}
                {/*  <h2>Welcome to React</h2>*/}
                {/*</div>*/}

                {frames.map((item, key) =>
                    <div style={{  position: "absolute", top: item.top, left: item.left, width: item.width, height: item.height, transform: 'scale('+item.zoom+')'}}>
                        <Iframe url={item.url}
                                width={item.width}
                                height={item.height}
                                id="myId"
                                position="absolute"
                            />
                 n     </div>
                )}
            </div>
        );
    }
}

export default App;
